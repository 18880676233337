import { Injectable } from '@angular/core';

import { Project, ProjectAndSenarios } from '@core/models/project.model';
import { ProjectsService } from '@core/services/projects.service';

import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { switchMap } from 'rxjs';

type State = Record<Project['ProjectNumber'], ProjectAndSenarios>;

@Injectable({ providedIn: 'root' })
export class ProjectsStore extends ComponentStore<State> {
  public readonly all$ = this.select((state) => Object.values(state));

  public selectByProjectNumber(projectNumber: Project['ProjectNumber']) {
    return this.select((state) =>
      Object.values(state).find(
        ({ details }) => details.ProjectNumber === projectNumber
      )
    );
  }

  public readonly load = this.effect((event$) =>
    event$.pipe(
      switchMap(() => this.projectsService.all()),
      tapResponse(
        (projects) => this.updateProjects(projects),
        (error) => console.error(error)
      )
    )
  );

  constructor(private readonly projectsService: ProjectsService) {
    super({});
  }

  private readonly updateProjects = this.updater(
    (_, projectAndSenarios: ProjectAndSenarios[]) => {
      const newState: State = {};
      for (const projectAndSenario of projectAndSenarios) {
        newState[projectAndSenario.details.ProjectNumber] = projectAndSenario;
      }
      return newState;
    }
  );
}
